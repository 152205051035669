import React, { useState } from 'react';
import './sponge.css'

function spongifyWord(word) {
  var result = '';
  var remainderToCapitalise = 1;

  function toggle(value) {
    if (value === 0) {
      value = 1
    } else {
      value = 0
    }
    return value
  }

  for (var i = 0; i < word.length; i++) {
    let thisChar = word.charAt(i);
    if (thisChar === 'i') {
      if (i % 2 === remainderToCapitalise) {
        // was going to capitalise
        remainderToCapitalise = toggle(remainderToCapitalise)
      }
      result = result + thisChar
      continue
    }
    if (i % 2 === remainderToCapitalise) {
      result = result + thisChar.toUpperCase()
    } else {
      result = result + thisChar.toLowerCase()
    }
  }
  return result
}

function spongify(input) {
  const words = input.split(' ');
  var result = [];
  for (let i = 0; i < words.length; i++) {
    const wordSpongified = spongifyWord(words[i]);
    result.push(wordSpongified)
  }
  return result.join(' ');
}

function SpongePage() {
  const [inputString, setInputString] = useState('');
  const [outputString, setOutputString] = useState('');
  
  function handleInputChange(event) {
    setInputString(event.target.value);
    const output = spongify(event.target.value)
    setOutputString(output);
  }
  
  function copyOutputToClipboard() {
    navigator.clipboard.writeText(outputString)
  }

  return (
    <div id="spongeContainer" >
      <textarea className="textinput" id="spongeInput" value={inputString} onChange={handleInputChange} spellCheck="false" />
      <p id="spongeOutput" style={{whiteSpace: "pre-wrap"}} onClick={copyOutputToClipboard}>{outputString}</p>
    </div>
  );
}

export default SpongePage;
