import Home from './home/home.js'
import JSONPage from './json/json.js'
import UUIDPage from './uuid/uuid.js'
import ColourPage from './colour/colour.js'
import SpongePage from './sponge/sponge.js'
import LeavesPage from './leaves/leaves.js'
import { HashRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/json' element={<JSONPage />} />
        <Route path='/uuid' element={<UUIDPage />} />
        <Route path='/colours' element={<ColourPage />} />
        <Route path='/sponge' element={<SpongePage />} />
        <Route path='/leaves' element={<LeavesPage />} />
      </Routes>
    </Router>
  );
}

export default App;
