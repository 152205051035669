import React, { useState, useRef } from 'react';
import './colour.css'

// Constants
const alphabet = Array.from(Array(26)).map((e, i) => i + 65).map((x) => String.fromCharCode(x)).slice(0, 6);
const allowedKeys = [[...Array(10).keys()].map(x => x.toString()), alphabet, alphabet.map(x => x.toLowerCase())].flat()

function hslToRgb(hue, saturation, luminance) {
  saturation /= 100;
  luminance /= 100;
  
  function k(n) {
    return (n + hue / 30) % 12;
  }
  
  const a = saturation * Math.min(luminance, 1 - luminance)
  function f(n) {
    return luminance - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
  }
  return `${Math.round(255 * f(0)).toString(16).padStart(2, '0')}${Math.round(255 * f(8)).toString(16).padStart(2, '0')}${Math.round(255 * f(4)).toString(16).padStart(2, '0')}`
}

function ColourPage() {
  const elementRef = useRef(null);

  // HSL
  const [hue, setHue] = useState(50);
  const [luminance, setLuminance] = useState(100);

  // RGB
  const [rgbHexString, setRgbHexString] = useState('16A6E0');
  
  // Page Settings
  const [paused, setPaused] = useState(false);
  const [usingRgb, setUsingRgb] = useState(false);

  function setHslColour(mouseX, mouseY) {
    const luminance = 100 * mouseY / elementRef.current.clientHeight
    const hue = 360 * mouseX / elementRef.current.clientWidth

    setHue(hue)
    setLuminance(luminance)
    setRgbHexString(hslToRgb(hue, 100, luminance))
  }

  function handleMouseMove(ev) {
    if (paused) { return }
    setHslColour(ev.nativeEvent.offsetX, ev.nativeEvent.offsetY)
  }

  function handleMouseClick(ev) {
    setUsingRgb(false)
    setPaused(currentValue => !currentValue)
  }

  function handleKeyPress(ev) {
    if (ev.key === '#') {
      setRgbHexString('')
      setUsingRgb(true)
      return
    } else if (ev.key === 'Backspace') {
      if (rgbHexString.length === 0) {
        setUsingRgb(false)
      } else {
        setRgbHexString(currentValue => currentValue.slice(0, -1))
      }
      return
    }
    
    if (allowedKeys.includes(ev.key)) {
      if (!usingRgb) {
        setUsingRgb(true)
      }
      if (rgbHexString.length < 6) {
        setRgbHexString(currentValue => currentValue + ev.key)
      } else {
        setRgbHexString(ev.key)
      }
      return
    } else {
      console.log(`key ${ev.key} is not valid for an RGB hex`)
      return
    }
  }
  
  function cssBackGroundColour() {
    if (usingRgb) {
      if (rgbHexString.length < 6) {
        return '#999999'
      } else {
        return `#${rgbHexString}`
      }
    } else {
      return `hsl(${hue}, 100%, ${luminance}%)`
    }
  }
  
  return (
    <div
      id="colourContainer"
      tabIndex={0}
      onMouseMove={(ev) => handleMouseMove(ev)}
      onMouseDown={(ev) => handleMouseClick(ev)}
      onKeyDown={handleKeyPress} 
      ref={elementRef}
      style={{
        backgroundColor: cssBackGroundColour(),
      }}
    >
      <p className="overlayText" style={{color: rgbHexString.length < 6 ? "red" : "black"}} >#{rgbHexString.toUpperCase()}</p>
      {paused && !usingRgb && <p className="overlayText" >⏸</p>}
    </div>
  );
}

export default ColourPage;
