import React, { useState } from 'react';
import './leaves.css'


function leavify(input) {
  const lines = input.split('\n');
  if (input.length == 0) {
    return ''
  }
  var result = [];
  for (let i = 0; i < lines.length; i++) {
    let line = lines[i]
    if (line.length == 0) {
      continue
    }
    if (line[0] !== '>') {
      line = '>' + line
    }
    result.push(line)
  }
  result.push('>refuses to elaborate')
  result.push('>leaves')
  return result.join('\n');
}

function LeavesPage() {
  const [inputString, setInputString] = useState('');
  const [outputString, setOutputString] = useState('');
  
  function handleInputChange(event) {
    setInputString(event.target.value);
    const output = leavify(event.target.value)
    setOutputString(output);
  }
  
  function copyOutputToClipboard() {
    navigator.clipboard.writeText(outputString)
  }

  return (
    <div id="leavesInput" >
      <textarea className="textinput" id="leavesInput" value={inputString} onChange={handleInputChange} spellCheck="false" />
      <p id="leavesOutput" style={{whiteSpace: "pre-wrap"}} onClick={copyOutputToClipboard}>{outputString}</p>
    </div>
  );
}

export default LeavesPage;
