import React, { useEffect, useState, useRef } from 'react';
import './uuid.css'

function UUIDCell(props) {
  const [clicked, setClicked] = useState(false);

  function handleClick(event) {
    setClicked(true);
    navigator.clipboard.writeText(event.shiftKey ? props.uuid.toUpperCase() : props.uuid);
  }

  return (
    <p className={clicked ? "clicked" : ""} onClick={handleClick}>{props.uppercase ? props.uuid.toUpperCase() : props.uuid}</p>
  )
}

function UUIDPage() {
  const [UUIDs, setUUIDs] = useState([]);
  const [uppercase, setUppercase] = useState(false);
  const uuidReference = useRef(null);

  useEffect(() => {
    setUUIDs(generateUUIDs(200));
    uuidReference.current.focus();
  }, []);

  function generateUUIDs(len) {
    let result = []
    for(let i = 0; i < len; i++) {
      result.push(crypto.randomUUID())
    }
    return result
  }

  function handleKeyDown(event) {
    if(event.key === 'Shift') {
      console.log('here')
      setUppercase(true);
    }
  }

  function handleKeyUp(event) {
    if(event.key === 'Shift') {
      setUppercase(false);
    }
  }

  return (
    <div>
      <ul id="uuidList" ref={uuidReference} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} tabIndex="-1">
        {UUIDs.map(uuid => <UUIDCell key={uuid} uuid={uuid} uppercase={uppercase}/>)}
      </ul>
    </div>
  );
}

export default UUIDPage;
